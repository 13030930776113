import { render, staticRenderFns } from "./quarantine-room.vue?vue&type=template&id=762d9314"
import script from "./quarantine-room.vue?vue&type=script&lang=ts"
export * from "./quarantine-room.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins_home/workspace/test-物联业务中台(test-nimbus_web_bi)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('762d9314')) {
      api.createRecord('762d9314', component.options)
    } else {
      api.reload('762d9314', component.options)
    }
    module.hot.accept("./quarantine-room.vue?vue&type=template&id=762d9314", function () {
      api.rerender('762d9314', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/dashboard/jyfc/quarantine-room.vue"
export default component.exports